import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"







const LSExperimental = () => {
  //javascript
  const title = 'Abutments';
  const metatitle = 'Abutments';
  const description = 'Meta Description for Abutments';
  const metadescription = description;

  const rel1 = '0.007 \cdot h_{w}';
  const rel2 = '0.04 \cdot h_{w}';
  const rel3 = '0.12 \cdot h_{w}';

  const rel4 = 'd_{gap}';
  const rel5 = '100mm';
  const rel6 = '2/3 \cdot (140+0.1 \cdot h_{w})';

  const rel7 = '0.003 \cdot h_{w}';
  const rel8 = '0.006 \cdot h_{w}';
  const rel9 = '0.06 \cdot h_{w}';

  const rel10 = '(0.027 - 0.03) \cdot h_{w}'; 
  const rel11 = '0.08 \cdot h_{w}';

  const rel12 = '(0.025 - 0.035) \cdot h_{w}'; 
  const rel13 = '(0.04 - 0.06) \cdot h_{w}'; 

  const rel14 = '120mm';
  const rel15 = '0.028 \cdot h_{w}';
  const rel16 = '0.044 \cdot h_{w}';
  const rel17 = '0.02 \cdot h_{w}';
  const rel18 = '0.062 \cdot h_{w}';

  const rel19 = '0.03 \cdot h_{w}';
  const rel20 = 'h_{w}=1.68m';
  const rel21 = '0.06 \cdot h_{w}';
  const rel22 = 'h_{w}=2.4m';
  const rel23 = '0.15 \cdot h_{w}';

  const rel24 = '0.0022 \cdot h_{w}';
  const rel25 = '0.03 \cdot h_{w}';

  const rel26 = '0.03 \cdot h_{w}';
  const rel27 = '0.1 \cdot h_{w}';

  const rel28 = '0.033 \cdot h_{w}';
  const rel29 = '0.052 \cdot h_{w}';

  const rel30 = '0.005 \cdot h_{w}';
  const rel31 = '0.03 \cdot h_{w}';
  const rel32 = '0.043 \cdot h_{w}';

  const rel33 = '0.05 \cdot h_{w}';
  const rel34 = '0.1 \cdot h_{w}';

  const rel35 = '1.10 \cdot d_{gap}';
  const rel36 = '0.01 \cdot h_{w}';
  const rel37 = '0.035 \cdot h_{w}';
  const rel38 = '0.10 \cdot h_{w}';

  const rel39 = '0.03 \cdot h_{w}';
  const rel40 = '>0.05 \cdot h_{w}';

  const rel41 = '0.029 \cdot h_{w}';
  const rel42 = '(0.058 - 0.0625) \cdot h_{w}';

  const rel43 = '(0.006 - 0.01) \cdot h_{w}';

  const rel44 = '0.047 \cdot h_{w}';
  const rel45 = '0.10 \cdot h_{w}';

  const rel46 = '0.014 \cdot h_{w}';

  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  
  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});

  const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }

  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <h2>Table 1: Abutments: Limit State thresholds in displacement terms</h2>
      <table>
        <thead>
          <tr>
            <th colSpan={4}>Engineering Demand Parameter: Displacement d (mm)</th>
          </tr>
          <tr>
            <th>Reference</th>
            <th>Limit State</th>
            <th>Threshold Value</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button>. Bozorgzadeh, A., Ashford, S., Restrepo, J., Nimityongskul, N. (2008)<br/></td>
            <td>LS2</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel1}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Stiffness reduction of abutment</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel2}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel3}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr className="even">
            <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button>. Cardone, D. (2013)</td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel4}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Gap closure between deck and abutment</td>
          </tr>
          <tr className="even">
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel5}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Passive resistance at backfill soil </td>
          </tr>
          <tr className="even">
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel6}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr>
            <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button>. Choi, (2002)</td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel7}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Slight damage, minor cracking and spalling of abutment</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel8}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Moderate damage, yielding of backfill soil</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel9}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Extensive damage, ultimate deformation</td>
          </tr>
          <tr className="even">
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref4)}>[4]</button>. Elgamal, A., Wilson, P. (2012)<br /><a href="https://doi.org/10.1007/978-94-007-2060-2_4" target="_blank">https://doi.org/10.1007/978-94-007-2060-2_4</a></td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel10}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr className="even">
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel11}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of backfill </td>
          </tr>
          <tr>
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref5)}>[5]</button>. Jesse, S., and Rollins, K., (2013)</td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel12}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel13}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr className="even">
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref6)}>[6]</button>. Kappos, A. and Sextos, A. (2009)</td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel14}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Gap closure</td>
          </tr>
          <tr className="even">
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel15}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Yielding of backfill soil </td>
          </tr>
          <tr className="even">
            <td rowSpan={2}>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel16}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td rowSpan={2}>Irrecoverable damage of abutment, pile head failure in the transverse direction</td>
          </tr>
          <tr className="even">
            <td>
              <MathJax.Context input='ascii'>
                <div>
                Transverse direction:<br /><MathJax.Node inline>{rel17}</MathJax.Node>, stiff soil<br /><MathJax.Node inline>{rel18}</MathJax.Node>, stiff soil<br />
                </div>
              </MathJax.Context>
            </td>
          </tr>
          <tr>
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref7)}>[7]</button>. Lemnitzer, A., Hilson, C., Taciroglu, E., Wallace, J.E., Stewart, J.P. (2012)</td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel19}</MathJax.Node>, for <MathJax.Node inline>{rel20}</MathJax.Node><br /><MathJax.Node inline>{rel21}</MathJax.Node>, for <MathJax.Node inline>{rel22}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel23}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr className="even">
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref8)}>[8]</button>. Mitoulis, S. (2012)<br /><a href="http://dx.doi.org/10.1016/j.engstruct.2012.05.033" target="_blank">http://dx.doi.org/10.1016/j.engstruct.2012.05.033</a></td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel24}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Yielding of backfill soil</td>
          </tr>
          <tr className="even">
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel25}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref9)}>[9]</button>. Romstad, K., Kutter, B.L., Maroney, B., Vanderbilt, E., Griggs, M., Chai, Y.H., (1995)</td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel26}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel27}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref10)}>[10]</button>. Rollins, K. M. and Cole, R. T. (2006)<br /><a href="https://doi.org/10.1061/(ASCE)1090-0241(2006)132:9(1143)" target="_blank">https://doi.org/10.1061/(ASCE)1090-0241(2006)132:9(1143)</a></td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel28}</MathJax.Node>, granular backfill<br /><MathJax.Node inline>{rel29}</MathJax.Node>, cohesive backfill
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref11)}>[11]</button>. Rollins, K. M., Gerber, T. M., Kwon, K. H. (2010)<br /><a href="https://doi.org/10.1061/(ASCE)GT.1943-5606.0000195" target="_blank">https://doi.org/10.1061/(ASCE)GT.1943-5606.0000195</a></td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel30}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Yielding of backfill soil</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel31}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel32}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of backfill</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref12)}>[12]</button>. Shamsabadi, A. M. and Yan, L. (2008)<br /><a href="https://doi.org/10.1061/40975(318)159" target="_blank">https://doi.org/10.1061/40975(318)159</a></td>
            <td>LS3-LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel33}</MathJax.Node>, granular backfill<br /><MathJax.Node inline>{rel34}</MathJax.Node>, cohesive backfill
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of backfill</td>
          </tr>
          <tr>
            <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref13)}>[13]</button>. Stefanidou S. &amp; Kappos A.(2017)<br /><a href="https://doi.org/10.1002/eqe.2774" target="blank">https://doi.org/10.1002/eqe.2774</a></td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel35}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Cracking and significant damage to the backwall</td>
          </tr>
          <tr>
            <td>LS2</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel36}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>First yield of the abutment soil</td>
          </tr>
          <tr>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel37}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Excessive deformation of abutment soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel38}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment soil (cohessionless soil)</td>
          </tr>
          <tr className="even">
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref14)}>[14]</button>. Stewart, J.P., Taciroglu, E., Wallace, W., Ahlberg, E. R., Lemnitzer, A., Rha, C., Tehrani, K., Keowen, S., Nigbor, R., Salamanca, A., (2007)</td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel39}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr className="even">
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel40}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr>
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref15)}>[15]</button>. Stewart, J.P., Taciroglu, E., Wallace, W., Lemnitzer, A., Hilson, C., Nojoumi, A., Keowen, S., Nigbor, R., Salamanca, A., (2011) (pp 54-58)</td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel41}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel42}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref16)}>[16]</button>. Taskari, O. and Sextos, A., (2012)</td>
            <td>LS1</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel43}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Backwall failure</td>
          </tr>
          <tr>
            <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref17)}>[17]</button>. Wilson, P., Elgamal, A., (2008)</td>
            <td>LS3</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel44}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Development of passive failure wedge in backfill soil</td>
          </tr>
          <tr>
            <td>LS4</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                    <MathJax.Node inline>{rel45}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Ultimate deformation of abutment-backfill system</td>
          </tr>
          <tr className="even">
            <td><button onClick={ e => executeScroll(e, Ref18)}>[18]</button>. Xie, Y., Huo, Y., Zhang, J. (2017)<br /><a href="https://doi.org/10.1002/eqe.2804" target="_blank">https://doi.org/10.1002/eqe.2804</a></td>
            <td>LS2</td>
            <td>
              <MathJax.Context input='ascii'>
                <div>
                Transverse direction:<br /><MathJax.Node inline>{rel46}</MathJax.Node>
                </div>
              </MathJax.Context>
            </td>
            <td>Sliding between wall and embankment</td>
          </tr>
        </tbody>
      </table>
      <div className="references">
        <h4>References</h4>
        <ol>
          <li ref={Ref1}>Bozorgzadeh, A., Ashford, S. A., Restrepo, J. I., Nimityongskul, N. (2008). Experimental and analytical investigation on stiffness and ultimate capacity of bridge abutments. Report No. SSRP-07/12, Structural Systems Research Project, University of California.</li>
          <li ref={Ref2}>Cardone, D. (2013). Displacement limits and performance displacement profiles in support of direct displacement-based seismic assessment of bridges, Earthquake Engineering &amp; Structural Dynamics, DOI: 10.1002/eqe.2396.</li>
          <li ref={Ref3}>Choi, E. (2002). Seismic Analysis and Retrofit of Mid-America Bridges, PhD Thesis, Georgia Institute of Technology, May, 2002.</li>
          <li ref={Ref4}>Elgamal A., Wilson P. (2012) Full Scale Testing and Simulation of Seismic Bridge Abutment-Backfill Interaction. In: Sakr M., Ansal A. (eds) Special Topics in Earthquake Geotechnical Engineering. Geotechnical, Geological and Earthquake Engineering, vol 16. Springer, Dordrecht, https://doi.org/10.1007/978-94-007-2060-2_4 </li>
          <li ref={Ref5}>Jesse, S. and Rollins, K. (2013). Passive Pressure on Skewed Bridge Abutments, 18th International Conference on Soil Mechanics and Geotechnical Engineering, Paris, France.</li>
          <li ref={Ref6}>Kappos, A. and Sextos, A., (2009). Seismic Assessment of bridges accounting for nonlinear material and soil response, and varying boundary conditions, NATO Security through Science Series C: Environmental Security, DOI: 10.1007/978-90-481-2697-2_15. </li>
          <li ref={Ref7}>Lemnitzer, A., Hilson, C., Taciroglu, E., Wallace, J. W., Stewart, J. P. (2012). Effect of Backfill Relative Density on Lateral Response of a Bridge Abutment Wall, 15th World Conference on Earthquake Engineering, Lisboa, Portugal.</li>
          <li ref={Ref8}>Mitoulis, S. (2012). Seismic Design of bridges with the participation of seat-type abutments, Engineering Structures, Vol. 44, pp 222-233, http://dx.doi.org/10.1016/j.engstruct.2012.05.033. </li>
          <li ref={Ref9}>Romstadt, K., Kutter, B.L, Maroney, B., Vanderbilt, E., Griggs, M., and Chai, Y.H. (1995). “Experimental measurements of bridge abutment behavior.” Rep. No UCD-STR-95-1, Structural Engineering Group, Univ. of California, Davis, CA.</li>
          <li ref={Ref10}>Rollins, K. M. and Cole, R. T. (2006). Cyclic Lateral Load Behavior of a Pile Cap and Backfill, Journal of Geotechnical and Geoenvironmental Engineering, ASCE, Vol. 132, pp 1143-1153, https://doi.org/10.1061/(ASCE)1090-0241(2006)132:9(1143). </li>
          <li ref={Ref11}>Rollins, K. M., Gerber, T. M., Kwon, K. H. (2010). Increased Lateral Abutment Resistance from Gravel Backfills of Limited Width, Journal of Geotechnical and Geoenvironmental Engineering, ASCE, Vol. 136, pp 230-238, https://doi.org/10.1061/(ASCE)GT.1943-5606.0000195. </li>
          <li ref={Ref12}>Shamsabadi, A. M. and Yan, L. (2008). Closed-Form Force-Displacement Backbone Curves for Bridge Abutment-Backfill Systems, Geotechnical Earthquake Engineering and Soil Dynamics IV Congress, GSP 181, ISBN (print): 9780784409756, https://doi.org/10.1061/40975(318)159 </li>
          <li ref={Ref13}>Stefanidou, S. &amp; Kappos A. (2017). Methodology for the development of bridge-specific fragility curves, Earthquake Engineering and Structural Dynamics, vol. 46, pp 73-93, https://doi.org/10.1002/eqe.2774.</li>
          <li ref={Ref14}>Stewart, J. P., Taciroglu, E., Wallace, J. W., Ahlberg, E. R., Lemnitzer, A., Rha, C., Tehrani, P. K., Keowen, S., Nigbor, R. L., Salamanca, A. (2007). Full Scale Cyclic Testing of Foundation Support Systems for Highway Bridges, Part II: Abutment Backwalls. Report No. 2007/02, Department of Civil and Environmental Engineering, University of California, Los Angeles. </li>
          <li ref={Ref15}>Stewart, J. P., Taciroglu, E., Wallace, J. W., Lemnitzer, A., Hilson, C. H., Nojoumi, A., Keowen, S., Nigbor, R. L., Salamanca, A. (2011). Nonlinear Load-Deflection Behavior of Abutment Backwalls with Varying Height and Soil Density, Report No. 2011/01, Department of Civil and Environmental Engineering, University of California, Los Angeles.</li>
          <li ref={Ref16}>Taskari, O. N. and Sextos, A. G. (2012). Stiffness and Ultimate Capacity of Typical Abutment-Embankment Systems. 15th World Conference on Earthquake Engineering, Lisboa, Portugal.</li>
          <li ref={Ref17}>Wilson, P. and Elgamal, A. (2008). Full scale bridge abutment passive earth pressure tests and calibrated models, The 14th Word Conference on Earthquake Engineering, October 12-17, 2008, Beijing, China.</li>
          <li ref={Ref18}>Xie, Y., Huo, Y., Zhang, J. (2017). Development and validation of p-y modeling approach for seismic response predictions of highway bridges, Earthquake Engineering and Structural Dynamics, vol. 46, pp 585-604, https://doi.org/10.1002/eqe.2804</li>
        </ol>  
      </div>
      
    </Layout>
  )
}

export default LSExperimental
